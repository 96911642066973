@import "./variables";
.k-daterangepicker {
  .k-label {
    transform: translate(-8px, -8px) translate(-1px, -1.4285714286em)
      translate(0%, 0%) scale(1);
  }
  .k-dateinput-wrap {
    border-radius: 7px;
  }
  .k-textbox-container {
    &:first-child {
      margin-right: 1rem;
    }
  }
  .k-daterangepicker-wrap {
    display: flex !important;
    flex-wrap: wrap;
  }
}
.k-textbox,
.k-input,
.k-numeric-wrap,
.k-dateinput-wrap {
  border-radius: 7px;
  box-shadow: $box-shadow !important;
}

.k-numeric-wrap,
.k-select,
.k-dropdown-wrap {
  border-radius: 7px !important;
  box-shadow: $box-shadow !important;
}

.k-chart {
  color: $text-color;
  .k-chart-surface {
    svg {
      border-radius: 7px !important;
      box-shadow: $box-shadow !important;
    }
  }
}
.k-grid-header {
  background-color: $box-secondary-color;
  border-radius: 2px;
  border: $border-width solid $primary-background;
  box-shadow: $box-shadow;
}
.k-grid-content {
  overflow: auto;
  background-color: $box-tertiary-color;
  border: none;
}
.k-grid .k-master-row .k-state-selected {
  background-color: $box-color;
  border: none;
}
.k-grid tbody tr:hover {
  background-color: $box-color !important;
}
.k-grid {
  border: none;
  color: $table-text-color;
  font-size: 12px;
  background-color: $box-secondary-color;
  .k-master-row {
    cursor: pointer;
    &.k-alt {
      background-color: $box-secondary-color;
    }
    td {
      border: none;
    }
  }
  tr {
    th {
      color: $table-header-color;
      border: none;
      font-weight: bold;
    }
  }
}
.k-pager {
  background-color: $box-secondary-color;
  color: $table-line-color;
}
.k-pager-numbers .k-link.k-state-selected {
  color: $table-pager-color;
  background-color: $table-pager-background-color;
}
.k-pager-numbers .k-link {
  color: $table-pager-color;
}
.k-pager-numbers .k-link:hover {
  color: $table-pager-color;
  background-color: $table-pager-background-color;
}
.k-pager-nav.k-link:hover {
  color: $table-pager-color;
  background-color: $table-pager-background-color;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .k-dateinput {
    width: 11.4em;
  }
  .k-dateinput-wrap {
    width: 100% !important;
  }
}
