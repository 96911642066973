@import "../../../styles/variables";

.TeamAssignmentForm {
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  border: $border-color solid $border-width;
  border-radius: $rounded-border-radius;
  background-color: $box-secondary-color;

  .MuiInputBase-input {
    color: $text-header-color !important;
    border: none !important;
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    color: white;
  }
  h4,
  h5 {
    color: $text-header-color;
    &.required {
      &:after {
        color: red;
        content: " *";
      }
    }
  }

  .user {
    margin: 8px;
  }
  .wearable {
    margin: 8px;
  }
  .team {
    margin: 8px;
  }
}