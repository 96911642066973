.MuiFormHelperText-root {
  color: var(--text-color) !important;
}

.MuiInputBase-input {
  color: var(--text-color) !important;
  border: none !important;
  background-color: transparent !important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--text-color) !important;
}
.MuiFormLabel-root {
  color: var(--text-color) !important;
}
.popup-button {
    background-color: #ADF5EA !important;
    border-radius: var(--border-radius) !important;
}
.popup-button-cancel {
    color: var(--text-color) !important;
    margin-right: 10px !important;
   
}