.IncidentNumberSummary {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  .icon-card {
    background-color: var(--icon-bg);
    border-radius: var(--border-radius);
    padding: 10px;
    width: 60px;
    height: 60px;
    .icon {
      max-width: 40px;
      max-height: 40px;
      width: 40px;
      height: 40px;
    }
  }
  .number-summary {
    color: var(--text-color);
    font: var(--font);
    align-items: left;
    .count {
      font-size: var(--font-XXL);
      font-weight: bold;
    }
    .text {
      font-size: var(--font-SM);
    }
  }
}
