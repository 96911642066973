@import "../../styles/variables";
.Settings {
  color: $text-color;
  min-width: 800px;

  .forbidden {
    font-size: 16px;
    text-align: center;
  }

  .k-grid tr .fixed {
    pointer-events: none;
  }

  h3 {
    color: $text-header-color;
  }

  .MuiInputBase-input {
    color: $text-header-color !important;
    border: none !important;
    background-color: transparent !important;
  }

  .k-content {
    color: $text-color;
    background-color: $box-color;
  }

  .k-animation-container {
    width: 100%;
  }

  .k-tabstrip-items .k-link {
    font-size: 16px;
    color: $text-header-color;
  }

  .k-tabstrip-items .k-item.k-state-active {
    color: $text-header-color;
    background-color: $box-color;

    border-bottom: $tab-highlight-color solid $border-width;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}
