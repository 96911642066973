@import '../../styles/variables';
.IncidentItem {
  border: 1px solid #ccc;
  box-shadow: $box-shadow;
  padding: 1rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  position: relative;
  background-color: #fff;
  .top_border {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100px;
    border: 3px solid red;
    border-top-left-radius: 5px;
    &.resolved {
      border: 3px solid $success-color;
    }
    &.pending {
      border: 3px solid $amber-color;
    }
  }
  .title_row {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    .title {
      font-weight: bold;
    }
  }
  .status {
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }

  .incident_row {
    padding-bottom: 0.5rem;
    svg {
      margin-right: 0.5rem;
    }
  }
}
