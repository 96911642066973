.LiveTeamView {
  .grid {
    margin-top: 10px;
  }
  .k-grid-header .k-header > .k-link {
    font-size: small !important;
    white-space: initial;
  }
  .table-icon {
    height: 15px;
    width: auto;
    margin: 10px;
    cursor: pointer;
  }

  .k-icon.k-i-sort-asc-sm {
    display: initial;
  }
}
