@import "../../styles/variables";
.YearlyDashboard {
  display: flex;
  flex-direction: column;
  min-width: 800px;
  .first_row {
    order: 1;
    height: 30px;
    display: flex;
    justify-content: space-between;
    margin: 0 0.5rem;
    align-items: center;
    .dashboard-type-selector {
      margin: 0 0 0.5rem 0;
    }
    .filters {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .monthSelect {
        display: flex;

        align-items: center;
        span {
          display: block;
          font-weight: bold;
          margin: 0 0.5rem 0 0;
          p {
            color: $text-color;
            padding: 0.25rem 0.5rem;
          }
        }
      }
    }
    .building-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 0.5rem 0;

      .MuiSelect-icon {
        color: white;
      }
      label {
        font-weight: bold;
        margin: 0 0.5rem 0 0;
        color: $text-color;
      }
      .MuiSelect-select.MuiSelect-select {
        padding-right: 24px !important;
      }
    }
  }
  .charts_row {
    order: 2;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    .Charts {
      width: 70%;
    }
  }
  .second_row {
    order: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .first_column {
      order: 1;
      width: 30%;
      margin: 8px;
      margin-left: 0 0.5rem 0.5rem;
      flex-grow: 1;
      float: left;
      .donut_incidents {
        width: 100%;
        float: left;
        margin-bottom: 8px;
      }
      .column_detection {
        width: 100%;
        float: left;
        margin-bottom: 8px;
      }
      .bar_locations {
        width: 100%;
        float: left;
        margin-bottom: 8px;
      }
      .bar_staff {
        width: 100%;
        float: left;
        margin-bottom: 8px;
      }
    }
    .second_column {
      order: 2;
      width: 70%;
      margin: 8px;
      margin-right: 0 0.5rem 0.5rem;
      flex-grow: 3;
      float: right;
      .stats_row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        width: 100%;
        float: right;
        margin-bottom: 8px;
        .stats_container {
          flex-grow: 1;
          width: 33%;
          background-color: $box-color;
          border-radius: $rounded-border-radius;
          box-shadow: $box-shadow;
          padding: 8px;
          margin-right: 8px;
          .title {
            display: inline-block;
            font-size: 12px;
            margin: 0.5rem 1rem;
            text-align: left;
            color: $text-color;
            text-transform: capitalize;
          }
          .value {
            font-size: 16px;
            margin: 0.5rem 1rem;
            text-align: left;
            color: $text-color;
            text-transform: capitalize;
            font-weight: bold;
          }
          .icon {
            padding: 8px;
            float: right;
          }
        }
      }
      .line_chart {
        width: 100%;
        float: right;
        margin-bottom: 8px;
      }
      .col_timeofday {
        width: 100%;
        float: right;
        margin-bottom: 8px;
      }
      .line_yoy {
        width: 100%;
        float: right;
        margin-bottom: 8px;
      }
      .incident_grid {
        background-color: $box-color;

        border-radius: $rounded-border-radius;
        box-shadow: $box-shadow;
        padding: 8px;
        float: right;
      }
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .YearlyDashboard {
    .first_row {
      height: 90px;
      flex-wrap: wrap-reverse;
    }
  }
}
