@import "../../styles/variables";
.StatusCell {
  &.new {
    color: $await-response-color;
    text-transform: capitalize;
  }
  &.open {
    text-transform: capitalize;
    color: $unresolved-color;
  }
  &.closed {
    text-transform: capitalize;
    color: $resolved-color;
  }
}
