@import "../../styles/variables";

.Home {
  display: grid;
  color: $text-color;
  margin: 0.5rem;
  .container {
    padding: 2.25rem 1.5rem;
  }
  .feature_title {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .welcome {
    color: $bright-blue-1;
    background-color: #f7f7f7;
    border-radius: $rounded-border-radius;
    box-shadow: $box-shadow;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      h2 {
        padding: 0.25rem;
        font-size: 24px;
      }
      p {
        padding: 0.25rem;
        font-size: 14px;
      }
    }
    .icon {
      img {
        width: auto;
        height: 100px;
      }
    }
  }

  .features {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .feature {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      display: flex;
      box-shadow: $box-shadow;
      border-radius: $rounded-border-radius;
      flex-direction: column;
      align-items: center;
      background-color: #f7f7f7;
      color: $bright-blue-1;
      .circle {
        height: 100px;
        width: 100px;
        background-color: #42425f;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon {
        padding: 0.25rem;
      }
      .title {
        padding: 0.25rem;
        h4 {
          font-size: 1rem;
          color: $bright-blue-1;
        }
      }
      .desc {
        padding: 0.25rem;
        text-align: center;
        font-size: 0.75rem;
      }
    }
  }
}
