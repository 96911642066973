@import "../../../styles/variables";
.cell {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  text-align: center;
  align-content: flex-start;

  .green-dot {
    height: 15px;
    width: 15px;
    background-color: $resolved-color;
    border-radius: 50%;
    display: inline-block;
    padding: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
  }
  .red-dot {
    height: 15px;
    width: 15px;
    background-color: $await-response-color;
    border-radius: 50%;
    display: inline-block;
    padding: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
  }
}
