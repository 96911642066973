.DailyOpsEmployeeStatusTableView {
  color: var(--text-color);
  font: var(--font);
  display: grid;
  row-gap: 10px;
  .header-row {
    display: grid;
    grid-template-columns: 3fr 1fr;
    .radio-section {
      justify-self: end;
    }
  }
  .table-icon {
    height: 15px;
    width: auto;
    cursor: pointer;
  }
  .table-icon-large {
    height: 20px;
    width: auto;
    cursor: pointer;
  }
}
.MuiRadio-colorPrimary.Mui-checked {
  color: var(--text-color) !important;
}
