.DailyOpsEmployeeStatus {
  color: var(--text-color);
  font: var(--font);

  .chart-card {
    margin-top: 10px;
  }
  .chart-title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: var(--font-M) !important;
    padding-bottom: 5px;
  }
  .MuiSlider-colorPrimary {
    color: #f2ad5b;
  }
  .MuiSlider-rail {
    height: 5px;
  }
  .summary-counts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: 10px;
    .number {
      font-size: var(--font-XXXL);
      font-weight: bold;
    }
    .in-zone {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: var(--light-gray);
      color: var(--text-color-inverse);
      margin-right: 10px;
    }
    .out-zone {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: var(--purple);
      color: var(--text-color);
      margin-left: 10px;
    }
    .icon {
      height: 100px;
      float: right;
    }
    .number-summary {
      display: grid;
      font: var(--font);
      align-items: left;
      .count {
        font-size: var(--font-XXL);
        // font-weight: bold;
      }
      .text {
        font-size: var(--font-SM);
      }
    }
    .inline-icon {
      margin-left: 10px;
      vertical-align: middle;
    }
  }
  .zone-breakdown {
    display: flex;
    row-gap: 20px;
    gap: 12px;
    margin-top: 10px;
    flex-wrap: wrap;
    .card {
      text-align: center;
      flex-basis: 32%;
      .count {
        font-size: var(--font-XL);
      }
      .number {
        font-size: var(--font-XXL);
        font-weight: bold;
      }
    }
  }
  .breakdown-row {
    margin: 10px;
  }
}
