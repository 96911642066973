@import "../../styles/variables";
.IncidentCell {
  text-transform: capitalize !important;
  &.unverified {
    color: $text-color;
  }
  &.near-miss {
    color: $near-miss-color;
  }
  &.fall {
    color: $fall-color;
  }
  &.hazard {
    color: $hazard-color;
  }
  &.hr-alert {
    color: $hr-color;
  }
  &.false-alarm {
    color: $gray-color;
  }
}
