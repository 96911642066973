.live-view-chart-legend {
  margin-top: 5%;
  vertical-align: middle;
  .legend-item {
    padding: 2.5px;
    font-size: var(--font-XS);
  }
}
.live-view-chart-dot {
  height: 10px;
  width: 10px;
  margin-right: 7.5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
