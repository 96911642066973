@import "../../styles/variables";
.LiveMapView {
  position: relative;
  height: 80vh;
  width: 100%;
  // overflow: hidden;
  .location-select {
    position: absolute;
    top: 0;
    z-index: 400;
    width: 200px;
    color: white;

    .placeholder {
      width: 100%;
      color: white;
      background-color: $blue-color-3;
      height: 35px;
      padding: 0.5rem 1rem;
      border-radius: 0;
      border-bottom-right-radius: 10px;
      display: flex;

      justify-content: space-between;
      align-items: center;
      &.expanded {
        border-bottom-right-radius: 0px;
      }
      svg {
        height: 100%;
      }
    }
    .sites {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: $blue-color-3;
      .site {
        height: 35px;
        width: 100%;
        height: 35px;
        color: white;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        &.selected,
        &:hover {
          background-color: #1952bf;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
    .zones {
      position: absolute;
      top: 35px;
      left: 203px;
      width: 200px;

      background-color: $blue-color-3;
      border-radius: 10px;
      .zone {
        padding: 0.5rem 1rem;
        display: flex;
        height: 35px;
        align-items: center;
        color: white;
        width: 100%;
        &.selected,
        &:hover {
          background-color: #1952bf;
          border-radius: 0;
          &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          &:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
          }
        }
      }
    }
  }
  .location-info {
    position: absolute;
    bottom: 1px;
    width: 100%;
    height: 150px;
    background-color: #2a3a5a59;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    .item {
      width: 100%;
      padding: 10px !important;
      margin: 4px;
      background-color: $primary-background;
      color: $text-color;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      .title {
        font-size: var(--font-L);
        text-align: left;
        color: $text-color;
      }
      .card-switch {
        margin-top: -40px;
        float: right;
      }
      .no-data {
        height: 100%;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .location {
        font-size: 1.25rem;
        height: calc(100% - 15px);
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        font-weight: bold;
      }
      .details {
        height: calc(100% - 15px);
        grid-template-columns: 1fr 1fr;
        display: grid;
        align-items: flex-end;
        .count {
          font-size: var(--font-XXXL);
        }
        .icon img {
          height: 50px;
          float: right;
        }
      }
      .details-3bar {
        height: calc(100% - 15px);
        grid-template-columns: 3fr 1fr;
        display: grid;
        align-items: flex-end;
        .count {
          font-size: var(--font-XXXL);
        }
        .icon img {
          height: 50px;
          float: right;
        }
      }
      .rings {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-end;
        svg {
          height: 100px;
        }
        .activities {
          width: 52%;
          .header {
            font-weight: bold;
            margin-bottom: 0.5rem;
          }
          .activity {
            display: flex;
            justify-content: space-between;
            line-height: 1rem;
            margin-top: 0.25rem;
            font-size: 0.7rem;
            .duration {
              font-size: 1.25rem;
              .duration1 {
                color: $ring1-color;
              }
              .duration2 {
                color: $ring2-color;
              }
              .duration3 {
                color: $ring3-color;
              }
              .duration4 {
                color: $ring4-color;
              }
              .goal {
                font-size: 0.75rem;
              }
            }
          }
        }
      }

      .n-workers {
        margin: 0 0.25rem 0 0.25rem;
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 0.25rem;
        column-gap: 1rem;

        .number-label,
        .number {
          font-size: var(--font-SM);
          display: flex;
          flex-direction: row;
          padding: 3px;
        }
        .number-label {
          justify-content: start;
        }
        .number {
          justify-content: end;
        }

        hr {
          grid-column: 1 / 3;
        }
      }
    }
  }
  .leaflet-container {
    height: 100%;
    .leaflet-tooltip {
      padding: 0;
      border: none;

      &::before {
        border: none;
      }
    }
  }
  .MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
  }
}

.leaflet-top, .leaflet-bottom {
  z-index: 998;
}