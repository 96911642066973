@import "../../styles/variables";
.SupervisingTeams {
  background-color: $box-color;

  .firstrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  .teams {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
