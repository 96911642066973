@import "../../styles/variables";
.wrapper {
  min-width: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 90%;
  .top_navbar {
    width: 100%;
    height: 50px;
    display: flex;
    top: 0px;
    z-index: 2;
    position: fixed;
    overflow-y: hidden;
    .top_menu {
      width: 100%;
      background: $box-color;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      border-bottom: $border-width solid $border-color;
      .logo {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        .title {
          color: $text-color;
          font-size: 1.25rem;
          font-weight: 500;
        }
        .image {
        }
      }
      ul {
        display: flex;
        text-align: center;
        li {
          height: 100%;
          border-bottom: 0.25px solid transparent;
          margin: 0 0.5rem;
          svg {
            font-size: 1rem;
            color: $text-color;
          }
        }

        li a {
          display: block;
          margin: 0 10px;
          height: 35px;
          width: 35px;
          line-height: 35px;
          text-align: center;
          color: $primary-dark;
          width: 50%;

          &:hover {
            width: 50%;
            border-bottom: 0.25px solid $primary-dark;
          }
        }
      }
    }
  }

  .main_container {
    width: 100%;
    overflow: visible;
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    transition: all 0.3s ease;
    background-image: linear-gradient(
      to right,
      $primary-background,
      $blue-color-1
    );
    .item {
      background: #fff;
      margin: 0.5rem;
      padding: 15px;
    }
    .breadcrumb {
      width: 100%;
      padding: 10px 0px;
      
    }
  }
  &.collapse {
    .sidebar {
      width: 70px;
      ul {
        width: 70px;
      }
      ul li a {
        text-align: center;
        span.icon {
          margin: 0;
        }
        span.title {
          display: none;
        }
      }
    }
    .main_container {
      width: calc(100% - 70px);
      margin-left: 70px;
    }
  }
}
