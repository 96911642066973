.dialog {
  .dialog-close-icon {
    float: right;
  }

.MuiDialog-container {
  width: 900px !important;
  margin: 0 auto !important;
}
.MuiDialog-paperWidthSm {
  max-width: 900px;
  width: 900px;
}
.MuiDialog-paper {
  background-color: var(--card-bg) !important;
  color: var(--text-color) !important;
}
.MuiDialogTitle-root {
  padding: 5px 10px !important;
}

}
