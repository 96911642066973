.LivePage {
  row-gap: 10px;
  display: grid;
  // grid-template-rows: 48px 1fr;
  .cardButton {
    color: var(--text-color-inverse);
    background-color: var(--light-green);
    float: right;
    text-transform: none;
  }
}
