.ProfileHealth {
  padding-top: 10px;
  padding-bottom: 5px;
  width: 100%;

  .selection {
    display: flex;
    align-content: space-between;
    flex-flow: column wrap;
  }
  .section {
    width: 100%;
    margin-top: 10px;
    height: 300px;
  }
  .custom-tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    padding: 10px 20px;
    .header {
      margin-bottom: 10px;
    }
    .label {
      font-size: small;
      color: gray;
      padding: 2px;
      border: 0.5px solid lightgray;
      .val {
        color: black;
      }
    }
  }
}
