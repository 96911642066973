@import "../../styles/variables";
.SupervisingTeamItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  margin: 8px;
  border: $border-color solid $border-width;
  border-radius: $rounded-border-radius;
  background-color: $box-secondary-color;

  .MuiInputBase-input {
    color: $text-header-color !important;
    border: none !important;
    background-color: transparent !important;
  }
  h4,
  h5 {
    color: $text-header-color;
  }
  .first_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 8px;
  }

  .teamName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 8px;
  }
  .line {
    border-bottom: $gray-color solid $border-width;
  }

  .firstResponders {
    margin: 8px;
    .users {
      display: flex;
      flex-direction: row;
      overflow: auto;
      .user {
        margin: 4px;
      }
    }
  }

  .escalationPoints {
    margin: 8px;
    .users {
      display: flex;
      flex-direction: row;
      overflow: auto;
      .user {
        margin: 4px;
      }
    }
  }
}
