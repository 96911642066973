@import "../../styles/variables";
.ProfilePage {
  .k-content {
    color: $text-color;
    background-color: $box-color;
  }

  .k-animation-container {
    width: 100%;
  }

  .k-tabstrip-items .k-link {
    font-size: 16px;
    color: $text-header-color;
  }

  .k-tabstrip-items .k-item.k-state-active {
    color: $text-header-color;
    background-color: $box-color;

    border-bottom: $tab-highlight-color solid $border-width;
    border-top: none;
    border-right: none;
    border-left: none;
  }
  .user-information {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 5px;
    background-color: #2a3b5b;
    margin-bottom: 10px;
    // min-height: 100px;
    .label {
      display: flex;
      flex-direction: row;
      padding: 3px;
      font-size: medium;
      color: white;
      .fa-icon {
        font-size: 40px;
        padding: 2px;
        margin-right: 10px;
      }
    }
  }
}
