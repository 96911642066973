.UserSvg {
  .a {
    fill: #4c87f7;
  }
  .a,
  .b {
    stroke: #000;
  }
  .b {
    fill: #3cb7cb;
  }
  .c {
    fill: #1d626e;
    stroke: #2d3a56;
  }
  &.alert {
    .a {
      fill: #f7d64c;
    }
    .a,
    .b {
      stroke: #000;
    }
    .b {
      fill: #3cb7cb;
    }
    .c {
      fill: #f7d64c;
      stroke: #000;
    }
  }
  .d,
  .e,
  .f,
  .g {
    fill: #fff;
  }
  .e,
  .g {
    font-size: 16px;
  }
  .e {
    font-family: HelveticaNeue, Helvetica Neue;
  }
  .f {
    font-size: 20px;
  }
  .f,
  .g {
    font-family: HelveticaNeue-Bold, Helvetica Neue;
    font-weight: 700;
  }
  .h {
    stroke: none;
  }
  .i {
    fill: none;
  }
  .j {
    filter: url(#c);
  }
  .k {
    filter: url(#a);
  }
}
.leaflet-popup-content-wrapper {
  background-color: #2d3a56;
 
}
.activity-row-popup {
  color: white;
}
.popup-button-dashboard {
  background-color: #adf5ea !important;
  border-radius: var(--border-radius) !important;
  height: 25px;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: #2d3a56;
}
.MuiFormHelperText-root {
  margin: 2px !important;
  font-size: smaller !important;
}
.MuiInputBase-input {
  font-size: smaller !important;
}
.user-marker-tooltip {
  border-radius: 5px;
  padding: 0.25rem;
  border: 1px solid #000;
  height: 120px;
  width: 200px;
  background-color: #2d3a56;
  .activity-row {
    color: white;
  }
  &.alert {
    background-color: #2d3a56;
    .activity-row {
      color: white;
    }
  }
  .activity-row {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 1px;
    font-size: 11px !important;
    .details,
    .icon {
      margin: 4px;
    }
    .activity {
      font-weight: bold;
      font-size: 12px;
      text-transform: capitalize;
    }
    .name {
      font-size: 12px;
      text-transform: capitalize;
    }
    .icon {
      img {
        height: 30px;
      }
    }
  }
  .duration-row {
    display: flex;
    float: right;
    align-items: center;
    color: #000;
    .value {
      font-weight: bold;
    }
  }
}
