@import "../../styles/variables";
.TimePeriods {
  display: block;
  .period {
    display: inline-block;
    text-align: center;
    padding: 0.25rem 1rem;
    background-color: $box-color;
    cursor: pointer;
    width: 125px;
    // box-shadow: 2px 2px $primary-dark;
    border: $border-width solid $border-color;
    color: $time-period-color;
    font-weight: bold;
    &.active,
    &:hover {
      background-color: $box-color;
      color: $yellow-color;
      // box-shadow: 0px 2px $primary-dark;
      border: $border-width solid $border-color;
    }
    &:nth-child(1) {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-right: 1px solid #000;
    }
    &:nth-child(3) {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border-left: 1px solid #000;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .TimePeriods {
    width: 100%;
    .period {
      width: 33%;
    }
  }
}
