.VulcanStore {
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  button {
    color: var(--white);
    background-color: var(--cool-blue);
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    width: 300px;
  }
  button:active,
  button:focus {
    outline: none;
  }
  @media only screen and (max-device-width: 480px){
    .actions {
      align-items: flex-start;
    }
  }
}
