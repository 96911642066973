@import "../../styles/variables";
.UserList {
  .first_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    color: $text-color;
    svg {
      height: 100%;
      color: $button-color;
    }
  }
  .btn-delete {
    min-width: 50px;
    background-color: red;
    color: white;
  }
}
