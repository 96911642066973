@import "../../styles/variables";
.User {
  color: $text-color;
  margin-top: 16px;
  margin-left: 32px;
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="date"],
  input[type="time"] {
    border: none;
    box-shadow: $box-shadow;
    padding: 0 1rem;
    &:focus {
      outline: none;
    }
  }
  .user-container {
    display: flex;
    .user-form {
      width: 50%;
      .form-row {
        margin: 1rem 0;
        display: flex;
        align-items: center;

        label {
          width: 30%;
          height: 100%;
          font-size: 14px;
          &.required {
            &:after {
              color: red;
              content: " *";
            }
          }
        }
        .MuiInputBase-input {
          background-color: $form-background-color !important;
        }
        input,
        .input {
          background-color: $form-background-color;
          color: $form-text-color;
        }

        input[type="checkbox"] {
          float: left;
          width: auto;
          margin: 0 0.5rem;
        }
        button {
          margin-right: 1rem;
        }
        select {
          width: 70%;
          border-radius: 20px;
          border: none;
          border-radius: $rounded-border-radius;
          border-bottom: $border-width solid $form-border-color;
          background-color: $form-background-color;
          color: $form-text-color;
          padding: 0.25rem;
          box-shadow: $box-shadow;
        }
        &.date-time {
          justify-content: space-between;
          label {
            width: auto;
            margin-right: 1rem;
          }
          input {
            width: auto;
            margin-right: 1rem;
          }
        }
        &.location {
          input {
            width: calc(35% - 0.12rem);
            margin-right: 0.25rem;
          }
        }
      }
      .rtsp-image {
        width: 100%;
        margin-top: 1rem;
        border-radius: $rounded-border-radius;
        box-shadow: $box-shadow;
      }
      button {
        float: right;
        width: 150px;
        margin-left: 8px;
        color: $button-text-color;
        background-color: $button-background-color;
        &:hover {
          color: $button-hover-text-color;
          background-color: $button-hover-background-color;
        }
      }
    }
  }
}
