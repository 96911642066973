@import "../../styles/variables";
.TeamForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  border: $border-color solid $border-width;
  border-radius: $rounded-border-radius;
  background-color: $box-secondary-color;

  .MuiInputBase-input {
    color: $text-header-color !important;
    border: none !important;
    background-color: transparent !important;
  }
  .MuiIconButton-label {
    color: white !important;
  }
  h4,
  h5 {
    color: $text-header-color;
    &.required {
      &:after {
        color: red;
        content: " *";
      }
    }
  }

  .teamName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 8px;
  }
  .line {
    border-bottom: $gray-color solid $border-width;
  }

  .firstResponders {
    margin: 8px;
  }

  .escalationPoints {
    margin: 8px;
  }
  .MuiAutocomplete-root {
    width: 100%;
  }
}
