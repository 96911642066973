@import "./styles/variables";
@import "./styles/worksafe-kendo.css";
@import "./styles/kendoStyles";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;

  text-decoration: none;
  ul {
    list-style: none;
  }
}
u {
  text-decoration: underline;
}
body {
  background-image: linear-gradient(
    to right,
    $primary-background,
    $blue-color-1
  );
  font-size: 12px;
}

.MuiDialogContent-dividers {
  border-top-color: white !important;
  border-bottom-color: white !important;
}
.MuiInputBase-input {
  color: $gray-color !important;
  padding: 0.25rem 0.5rem !important;
  border: $border-width solid $border-color !important;
  border-radius: 5px !important;
  background: $box-color !important;
}

::-webkit-scrollbar {
  width: 8px;
  background: $dark-gray-color;
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: $gray-color;
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}

button,
input[type="submit"] {
  border: none;
  border-radius: 30px;
  background: $primary-dark;
  height: 32px;
  color: #fff;

  cursor: pointer;
  box-shadow: $box-shadow;
  &:hover {
    background: #fff;
    color: #000;
  }
  &:focus {
    outline: none;
  }
}

button {
  background: none;
  box-shadow: none;

  cursor: pointer;
  color: $primary-button-blues;
  font-weight: bold;
}

select:focus {
  outline: none;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}

.error-message {
  background-color: rosybrown;
  color: #fff;
  padding: 0.5rem;
  margin-top: 1.5rem;
}

.Toastify__toast {
  border-radius: 5px !important;
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  border-radius: 0;
  min-width: 0;
  &:hover {
    background: transparent;
    color: #fff;
  }
}
#sidebar {
  position: fixed;
  width: 200px;
  height: 100%;
  background-color: #ffffff;
  left: -200px;
  transition: all 500ms;
  &.active {
    left: 0px;
  }
  ul {
    margin-top: 50%;
    li {
      color: $primary;
      background-color: #fff;
      padding: 15px 10px;
    }
  }
  .toggle-btn {
    position: absolute;
    left: 230px;
    top: 20px;
    cursor: pointer;
    span {
      display: block;
      width: 30px;
      height: 5px;
      background-color: #151719;
      margin: 3px 0px;
    }
  }
}


:root {
  --text-color: #FFFFFF;
  --text-color-inverse : #000;
  --font :'roboto';
  --font-XXXL : 40px;
  --font-XXL : 25px;
  --font-XL : 20px;
  --font-L: 18px;
  --font-M : 15px;
  --font-SMM : 13px;
  --font-SM : 12px;
  --border-radius: 10px;
  --card-bg: #2D384E;
  --icon-bg:white;
  --light-gray: #CCC4CC;
  --purple:#A37BFC;
  --white:white;
  --table-header-bg:#8FA0C3;
  --table-row-bg-odd:#3B4860;
  --table-row-bg-even:#2E394E;
  --light-green:#ADF5EA;
  --dull-orange:#F2AD5B;
  --cool-blue:#1eaaf1;
}
.form-element {
  height: 30px;
  border-radius: var(--border-radius);
  min-width: 175px;
  margin-right: 15px;
  font-size: var(--font-M);
  padding:5px;
}
.short-element {
  min-width: 100px;
}
.long-element {
  min-width: 250px !important;
}
.card {
  background-color: var(--card-bg);
  border-radius: var(--border-radius);
  width: 100%;
  min-height: 20px;
  padding:15px;
}