@import "../../styles/variables";
.IncidentReport {
  width: 100%;
  color: $text-color;
  .MuiSelect-icon {
    color: white;
  }
  form label.required {
    font-weight: bold;
    &:after {
      color: red;
      content: " *";
    }
  }

  button {
    color: $button-text-color;
    background-color: $button-background-color;
    &:hover {
      color: $button-hover-text-color;
      background-color: $button-hover-background-color;
    }
    &.form-submit-btn {
      width: 80px;
      min-width: 50px;
    }
    &.close {
      position: fixed;
      right: 50px;
      z-index: 3;
      width: 80px;
      min-width: 50px;
    }
    &.view-history {
      position: relative;
      width: 140px;
      min-width: 140px;
    }
    &.hide-history {
      width: 160px;
      min-width: 160px;
    }

    &.download {
      position: absolute;
      bottom: 15px;
      right: 15px;
      z-index: 3;
      width: 115px;
    }
  }
  .header {
    margin: 2rem 0.5rem;
    color: $text-color;
    font-weight: bold;
    font-size: 1.15rem;
  }
  .content {
    display: flex;
    margin: 2rem 0.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    .img-container {
      width: 40%;
      img,
      video {
        width: 100%;

        border-radius: 5px;
        object-fit: contain;
      }
      video {
        height: 100%;
      }
      .audio-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: $rounded-border-radius;
      }
    }
    .incident-form {
      width: 55%;
      &.w-100 {
        width: 100%;
      }
      .form-row {
        margin: 0 0 1rem 0;
        display: flex;
        align-items: center;

        label {
          width: 100%;
          height: 100%;
          font-weight: bold;
          display: inline-block;
        }
        input,
        .input {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: $rounded-border-radius;
          border-bottom: $border-width solid $form-border-color;
          background-color: $form-background-color;
          color: $form-text-color;
          padding: 0.5rem;
          &:focus {
            outline: none;
          }
        }
        button {
          margin-right: 1rem;
        }
        select {
          width: 100%;
          border: none;
          border-radius: $rounded-border-radius;
          border-bottom: $border-width solid $form-border-color;
          background-color: $form-background-color;
          color: $form-text-color;
          padding: 0.25rem;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .IncidentReport {
    .img-container {
      width: 100% !important;
      img {
        height: 200px !important;
      }
      audio {
        height: 50%;
        width: 50%;
      }
    }
    .incident-form {
      margin: 0.25rem !important;
      width: 100% !important;
      .form-row {
        &:first-child {
          margin: 1rem 0 !important;
        }
      }
      input {
        padding: 0.25rem !important;
      }
      button {
        &.download {
          position: relative !important;
          bottom: 0 !important;
          right: 0 !important;
          width: auto;
        }
      }
    }
  }
}
