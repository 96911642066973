@import "../../styles/variables";
.QRCodeButton {
  button {
    display: block;
    margin: 0 10px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    color: $primary-dark;
    width: 50%;

    &:hover {
      width: 50%;
      border-bottom: 0.25px solid $primary-dark;
    }
    svg {
      font-size: 1rem;
      color: $text-color;
    }
  }
  button {
    &:hover {
      background: none;
    }
  }
}
.QRCode {
  button {
    color: #e5e5e5;
    background-color: #3c4962;
  }
  button.close {
    position: fixed;
    right: 50px;
    z-index: 3;
    width: 80px;
    min-width: 50px;
  }
  .header {
    margin: 2rem 0.5rem;
    color: $blue-color-1;
    font-weight: bold;
    font-size: 1.15rem;
  }
  section {
    margin: 50px;
    text-align: center;
    color: $blue-color-1;
    font-size: 1rem;
    img {
      padding: 1rem;
    }
  }
}
