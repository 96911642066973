.SiteMapView {
  .map-marker-pin {
    background-image: url(../../resources/icons/dailyOps/main-map-pin.svg);
    background-repeat: no-repeat;
    width: 30px !important;
    height: 30px !important;
    background-size: contain;
    margin: 0 auto;
    color: white !important;
    font-size: medium;
    padding-left: 25px;
    .text {
      white-space: nowrap;
      padding: 2px;
      background-color: #299b2d;
      display: table;
    }
  }
  .top-left {
    .text {
      display: block;
      width: max-content;
      margin-left: -155px;
      margin-top: -20px;
    }
  }
  .top-right {
    .text {
      display: block;
      width: max-content;
      margin-top: -20px;
      margin-left: 0px;
    }
  }
  .bottom-right {
    .text {
      display: block;
      width: max-content;
      margin-top: 20px;
      margin-left: 0px;
    }
  }
  .bottom-left {
    .text {
      display: block;
      width: max-content;
      margin-left: -155px;
      margin-top: 20px;
    }
  }
  .map-marker-pin-incedents {
    background-image: url(../../resources/icons/dailyOps/main-map-pin-red.svg);
    background-repeat: no-repeat;
    width: 30px !important;
    height: 30px !important;
    background-size: contain;
    margin: 0 auto;
    color: white !important;
    font-size: medium;
    padding-left: 25px;
    .text {
      white-space: nowrap;
      background-color: #eb2a2a;
      display: table;
      padding: 2px;
    }
  }

  .site-selection {
    position: absolute;
    top: 0;
    right: 2;
    z-index: 400;
    width: 200px;
    color: white;
  }
  .goto-site {
    position: absolute;
    top: 0;
    z-index: 400;
    width: 100%;
    color: black;
    font-size: large;
  }
  .MuiSelect-select.MuiSelect-select {
    color: black !important;
  }
}
