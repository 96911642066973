.BoxPlotChart {
  width: 100%;
  height: 100%;
  .custom-tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    padding: 10px 20px;
    .header {
      margin-bottom: 10px;
    }
    .label {
      font-size: small;
      color: gray;
      padding: 2px;
      border: 0.5px solid lightgray;
      .val {
        color: black;
      }
    }
  }
}
