@import "../../styles/variables";

.Login {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to right, $bright-blue-1, $bright-blue-2);
  font-size: 14px;
  .loginBox {
    width: 22%;
    min-width: 300px;
    max-width: 400px;
    background: $bright-blue-1;
    border-radius: $rounded-border-radius;
    color: $text-color;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 50px 30px;
    box-shadow: $box-shadow;
    .avatar {
      width: 100%;
      text-align: center;
      color: $text-color;
    }
    .title {
      text-align: center;
      color: $text-header-color;
      margin-bottom: 1.5rem;
      font-size: 2rem;
      font-weight: 500;
    }
    h1 {
      padding: 0;
      margin: 0;
      text-align: center;
      font-size: 2rem;
      color: $text-header-color;
      margin-bottom: 1.5rem;
    }
    .inputField {
      border: none;
      border-radius: $rounded-border-radius;
      border-bottom: $border-width solid $form-border-color;
      background-color: $form-background-color;
      color: $form-text-color;
      padding: 0 0.5rem;
      box-shadow: $box-shadow;
      margin: 0.75rem 0;
    }
    .error-message {
      background-color: transparent;
      color: $fall-color;
      text-align: center;
      margin-top: 0.5rem !important;
    }
    input {
      width: 100%;
      border: none;
      // border-bottom: 1px solid #fff;
      background: $form-background-color;
      outline: none;
      color: $text-color;
      font-size: 14px;
      padding: 5px;
    }
    button {
      width: 100%;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      box-shadow: $box-shadow;
      font-weight: 600;
      color: $button-text-color;
      background-color: $bright-blue-3;
      &:hover {
        color: $button-hover-text-color;
        background-color: $bright-blue-3;
      }
    }
    a {
      padding-top: 0.5rem;
      text-decoration: none;
      font-size: 12px;
      line-height: 20px;
      display: block;
      &:hover {
        color: #fff;
      }
    }
  }
  .qrLogin {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* grid-row-gap: 10px; */
    /* row-gap: 10px; */
    width: 100%;
    /* height: 400px; */
    background: #1a2c62;
    border-radius: 10px;
    color: white;
    /* top: 50%; */
    /* left: 50%; */
    /* position: absolute; */
    /* transform: translate(-50%, -50%); */
    box-sizing: border-box;
    box-shadow: $box-shadow;
  }
  .loginBoxInTab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 5px;
    .avatar {
      height: 15px;
      text-align: center;
      color: $text-color;
    }
    .title {
      text-align: center;
      color: $text-header-color;
      margin-bottom: 1 rem;
      font-size: 1rem;
      font-weight: 500;
    }
    h1 {
      padding: 0;
      margin: 0;
      text-align: center;
      font-size: 2rem;
      color: $text-header-color;
      margin-bottom: 1.5rem;
    }
    .inputField {
      border: none;
      border-radius: $rounded-border-radius;
      border-bottom: $border-width solid $form-border-color;
      background-color: $form-background-color;
      color: $form-text-color;
      padding: 0 0.5rem;
      box-shadow: $box-shadow;
      margin: 0.75rem 0;
    }
    .error-message {
      background-color: transparent;
      color: $fall-color;
      text-align: center;
      margin-top: 0.5rem !important;
    }
    input {
      width: 100%;
      border: none;
      // border-bottom: 1px solid #fff;
      background: $form-background-color;
      outline: none;
      color: $text-color;
      font-size: 14px;
      padding: 5px;
    }
    button {
      width: 100%;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      box-shadow: $box-shadow;
      font-weight: 600;
      color: $button-text-color;
      background-color: $bright-blue-3;
      &:hover {
        color: $button-hover-text-color;
        background-color: $bright-blue-3;
      }
    }
    a {
      padding-top: 0.5rem;
      text-decoration: none;
      font-size: 12px;
      line-height: 20px;
      display: block;
      &:hover {
        color: #fff;
      }
    }
  }
  .MuiTab-wrapper {
    font-size: small;
  }
}
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 100px !important;
    max-width: 100px !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .Login {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to right, $bright-blue-1, $bright-blue-2);
    font-size: 14px;
    .loginBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 75%;
      min-width: 300px;
      max-width: 400px;
      background: $bright-blue-1;
      border-radius: $rounded-border-radius;
      color: $text-color;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
      padding: 50px 30px;
      box-shadow: $box-shadow;
      .avatar {
        width: 100%;
        align-self: center;
        text-align: center;
        color: $text-color;
      }
      .title {
        text-align: center;
        color: $text-header-color;
        margin-bottom: 1.5rem;
        font-size: 2rem;
        font-weight: 500;
      }
      h1 {
        padding: 0;
        margin: 0;
        text-align: center;
        font-size: 2rem;
        color: $text-header-color;
        margin-bottom: 1.5rem;
      }
      .inputField {
        border: none;
        border-radius: $rounded-border-radius;
        border-bottom: $border-width solid $form-border-color;
        background-color: $form-background-color;
        color: $form-text-color;
        padding: 0 0.5rem;
        box-shadow: $box-shadow;
        margin: 0.75rem 0;
      }
      .error-message {
        background-color: transparent;
        color: $fall-color;
        text-align: center;
      }
      input {
        width: 100%;
        border: none;
        // border-bottom: 1px solid #fff;
        background: $form-background-color;
        outline: none;
        color: $text-color;
        font-size: 14px;
        padding: 5px;
      }
      button {
        width: 100%;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        box-shadow: $box-shadow;
        font-weight: 600;
        color: $button-text-color;
        background-color: $bright-blue-3;
        &:hover {
          color: $button-hover-text-color;
          background-color: $bright-blue-3;
        }
      }
      a {
        padding-top: 0.5rem;
        text-decoration: none;
        font-size: 12px;
        line-height: 20px;
        display: block;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
