.IncidentListMobile {
  display: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .IncidentListMobile {
    display: block;
    select {
      display: block;
      width: 100%;
      height: 30px;
      border: 1px solid;
      border-radius: 5px;
      padding: 0.25rem;
    }
  }
}
