@import "../../styles/variables";

.DailyOpsView {
  // margin: -20px -15px;
  // height: calc(100% + 40px);
  // width: calc(100% + 30px);
  display: grid;
  row-gap: 10px;
  .k-label {
    color: $text-color;
    transform: none;
    font-size: 50%;
  }
  .location {
    display: none;
  }
  .map-section {
    position: relative;
    min-height: 75vh;
    grid-row: 2;

    .panel-toggle {
      z-index: 1000;
      position: absolute;
      top: 0;
      width: 3em;
      height: 1.5em;
      background-color: $primary-background;
      border-radius: 0px 0px 5px 5px;
    }

    #activity-type-selector {
      position: absolute;
      top: 1em;
      right: 1em;
      z-index: 1000;
    }

    #heatmap-legend {
      z-index: 1000;
      position: absolute;
      background-color: $box-color;
      bottom: 2em;
      right: 1em;
      border-radius: 5px;
      height: 5em;
      width: 22em;

      #heatmap-spectrum {
        position: absolute;
        bottom: 0px;
        margin: 0em 5em 1em 5em;
        height: 2em;
        width: 12em;
        background: linear-gradient(
          90deg,
          cornflowerblue,
          cyan,
          springgreen,
          yellow,
          orange,
          red
        );
      }

      .heatmap-label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0.5em;
        width: 8em;
        color: white;
      }

      #heatmap-least {
        left: 1em;
      }

      #heatmap-most {
        right: 1em;
      }
    }

    .leaflet-container {
      height: 100%;
      .leaflet-tooltip {
        padding: 0;
        border: none;

        &::before {
          border: none;
        }
      }
    }
  }
  .heat-map-slider {
    z-index: 1000;
    position: absolute;
    bottom: 2em;
    left: 1em;
    min-width: calc(100% - 350px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    .k-slider {
      width: 100%;
      margin-bottom: 1em;
    }
  }
  .k-label {
    color: var(--text-color-inverse);
  }

  .MuiInputBase-root {
    border-radius: var(--border-radius);
    min-width: 175px;
    width: 175px;
    margin-right: 15px;
    font-size: var(--font-M);
    padding: 5px;
    background-color: var(--white);
    display: inline-flex;
  }
  .MuiAutocomplete-root {
    display: inline-flex;
  }
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
    padding: 0 !important;
  }
  #size-small-standard {
    height: 22px;
    border-radius: var(--border-radius);
    min-width: 150px;
    margin-right: 15px;
    font-size: var(--font-SM);
    padding: 5px;
    color: var(--text-color-inverse) !important;
  }
}

.k-slider .k-slider-selection {
  background-color: var(--dull-orange);
}

.k-slider .k-draghandle {
  border-color: var(--dull-orange);
  background-color: var(--dull-orange);
}
