@import "../../styles/variables";
.WorkShift {
  color: $text-color;

  .MuiDialogContent-root {
    .map {
      height: 100%;
    }
  }
  .grid {
    margin-top: 0.5rem;
  }
}
