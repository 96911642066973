@import "../../styles/variables";
.DailyDashboard {
  .zero_row {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0 0.5rem 0.5rem 0;
  }
  .first_row {
    min-width: 800px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .daterange {
        margin: 0 0.5rem 0.5rem;
        display: flex;

        .mbl-dt {
          display: flex;
          margin-right: 1rem;
          align-items: center;
          span {
            display: block;
            font-weight: bold;
            margin: 0 0.5rem 0 0;
            p {
              color: $text-color;
            }
          }
          .MuiInputBase-input {
            max-width: 120px;
          }
        }
      }

      .team-select {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 0.5rem 0.5rem;

        .MuiSelect-icon {
          color: white;
        }
        label {
          font-weight: bold;
          margin: 0 0.5rem 0 0;
          color: $text-color;
        }
        .MuiSelect-select.MuiSelect-select {
          padding-right: 24px !important;
        }
      }

      .building-select {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0rem 0.5rem 0.5rem 0.5rem;

        .MuiSelect-icon {
          color: white;
        }
        label {
          font-weight: bold;
          margin: 0 0.5rem 0 0;
          color: $text-color;
        }
        .MuiSelect-select.MuiSelect-select {
          padding-right: 24px !important;
        }
      }
    }

    .dashboard-type-selector {
      margin: 0 0 0.5rem 0;
    }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .DailyDashboard {
      .first_row {
        height: 90px;
        flex-wrap: wrap-reverse;
        //flex-direction: column-reverse;
        .daterange {
          margin: 0.5rem 0;
          .k-daterangepicker {
            display: none;
          }
          .mbl-dt {
            display: inline-block !important;
          }
        }
      }
    }
  }
}
