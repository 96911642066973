@import "../../styles/variables";

.Building {
  background-color: $box-color;

  .firstrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  .buildings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
