@import "../../styles/variables";
.IncidentList {
  display: block;
  .summary_row {
    width: 100%;
    min-width: 800px;
    display: flex;
    overflow: auto;
    justify-content: space-between;
  }
  .summary_row > :first-child {
    margin-left: 0 !important;
  }
  .summary_row > :last-child {
    margin-right: 0 !important;
  }

  .content_row {
    display: grid;
    column-gap: 2rem;
    row-gap: 1rem;
    grid-template: auto 1fr / 20% 0px 1fr;
    background-color: $box-color;
    height: calc(100vh - 275px);
    border-radius: $rounded-border-radius;

    box-shadow: $box-shadow;

    padding: 1rem;
    min-width: 800px;
    min-height: 400px;
    & > * {
      min-height: 0px;
    }

    .floating-report-button-container {
      position: relative;
      grid-column: 3 / span 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: $border-width solid $divider-color;
      .title {
        color: $text-header-color;
        font-weight: bold;
        font-size: 24px;
        flex-grow: 1;
      }

      button {
        float: right;
        display: flex;
        height: 80%;
        align-items: center;
        justify-content: center;
        padding: 0px 1em;
        color: $button-text-color;
        background-color: $button-background-color;
        &:hover {
          color: $button-hover-text-color;
          background-color: $button-hover-background-color;
        }
      }
    }
    .floor_plan_title {
      grid-row: 1 / span 1;
      color: $text-header-color;
      font-weight: bold;
      font-size: 24px;
      margin-left: 0.5rem;
      border-bottom: $border-width solid $divider-color;
    }

    .floor_plan {
      grid-row: 2 / span 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: auto;
      .location {
        width: 90%;
        background-color: $box-secondary-color;
        border: $border-width solid $border-color;
        border-radius: $rounded-border-radius;
        color: $text-color;
        margin: 10px;
        padding: 10px;
        box-shadow: $box-shadow;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        cursor: pointer;
        &.active {
          background-color: $box-secondary-color;
          border: $border-width solid $border-color;
          border-bottom: $selected-border-width solid $selected-color;
        }
        &:hover {
          background-color: $box-secondary-color;
          border: $border-width solid $border-color;
          border-bottom: $selected-border-width solid $selected-color;
        }
        .info {
          display: flex;
          flex-direction: column;
          padding-left: 5px;
          .title {
            font-size: 16px;
            font-weight: bold;
          }
          .details {
            margin-top: 4px;
            font-size: 12px;
          }
        }
        .icon {
          float: left;
        }
      }
    }
    .v_line {
      grid-row: 1 / span 2;
      border: 1px solid #ccc;
    }
    .grid {
      position: relative;
      .download {
        position: absolute;
        bottom: -5px;
        right: 10px;
        padding: 0px 1em;
        color: $button-text-color;
        background-color: $button-background-color;
        &:hover {
          color: $button-hover-text-color;
          background-color: $button-hover-background-color;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .IncidentList {
    display: none;
    .summary_row {
      display: none;
    }
    .content_row {
      display: none;
    }
  }
}
