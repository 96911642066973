
  .marker-tooltip {
    border-radius: 5px;
    padding: 0.25rem;
    border: 1px solid #000;
    height: 75px;
    width: 150px;
    color: white;
    background-color: #d30c0c;
    
    &.raking {
      background-color: #1d626e;
    }
    .incident-row {
      display: flex;
      justify-content: space-between;
      padding: 2px;
      .details,
      .icon {
        margin: 4px;
      }
      .details {
        width: 100%;
      }
      .incident {
        font-weight: bold;
        font-size: 12px;
        text-transform: capitalize;
      }
      .name {
        font-size: 12px;
        text-transform: capitalize;
        img {
          margin-right: 0.5rem;
        }
      }
      .icon {
        img {
          height: 20px;
        }
      }
      .date-row {
        display: flex;
        justify-content: space-between;
        .next-img {
          cursor: pointer;
        }
      }
    }
  }
  .marker-tooltip.gray {
    background-color: #808080;
  }