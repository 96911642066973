@import "../../styles/variables";
.Wearable {
  background-color: $box-color;

  .firstrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  .table {
    margin: 8px;
  }
}
