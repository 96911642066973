.MuiAutocomplete-input {
  height: 30px;
  border-radius: var(--border-radius);
  min-width: 250px;
  margin-right: 15px;
  font-size: var(--font-SM);
  padding: 5px;
}
.MuiAutocomplete-root {
  display: inline-block;
}
