.DilyOpsOverview {
  display: grid;
  gap: 15px 10px;
  color: var(--text-color);
  .summary-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
  }
  .chart-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    .chart-title {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font: var(--font);
      font-size: var(--font-M) !important;
      padding-bottom: 5px;
      .chart-sub-title {
        font: var(--font);
        font-size: var(--font-SM) !important;
        text-align: right;
        vertical-align: middle;
        .link {
          color: var(--text-color);
          text-decoration: underline;
        }
      }
    }
    .information-icon {
      min-width: 10px;
      max-width: 10px;
      margin-left: 5px;
    }
  }
  .dot {
    height: 10px;
    width: 10px;
    margin-right: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  .number {
    margin-bottom: 20px !important;
  }
  .chart-container {
    // max-height: 400px;
    // margin-bottom: 10px;
  }
  .card-button {
    color: var(--text-color-inverse);
    background-color: var(--light-green);
    float: right;
    text-transform: none;
    margin-top: 5px;
    height: 25px;
  }
  .MuiButton-root:hover {
    text-decoration: none;
    background-color: var(--light-green);
  }
}
