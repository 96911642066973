$font-primary: "roboto", "Montserrat", Arial, sans-serif;

//darkest
$primary: rgba(167, 205, 248, 1);
//darker
$primary-background: #202735;
//dark
$primary-dark: #26374c;
$primary-button-blues: rgb(97, 131, 252);
// $primary-dark: #4d84ff;

$primary-font-color: #000;

$dark-blue-text: rgb(43, 65, 150);

$amber-color: rgb(220, 135, 37);

$success-color: rgb(104, 147, 93);

$danger-color: rgb(152, 14, 15);

$gray-color: #e5e5e5;
$dark-gray-color: #9d9d9d;
$yellow-color: #ffbd2f;

$fall-color: #ff106e;
$near-miss-color: #6296f8;
$hazard-color: #ffbd2f;
$hr-color: #ef0f0f;

$await-response-color: #ff106e;
$unresolved-color: #ffbd2f;
$resolved-color: #1ed9c3;

$rounded-border-radius: 10px;

$border-color: black;

$border-width: 0.5px;
$selected-border-width: 1.5px;

$blue-color-1: #2e394e;
$blue-color-2: #3c4962;
$blue-color-3: #2a3a5a;

$bright-blue-1: #1a2c62;
$bright-blue-2: #161e2d;
$bright-blue-3: #4466f4;

$shadow-color: #3c4962;
$time-period-color: $gray-color;
$divider-color: $dark-gray-color;

$text-color: white;
$text-header-color: white;

$box-color: $blue-color-3;
$box-secondary-color: $blue-color-1;
$box-tertiary-color: $blue-color-2;

$table-header-color: white;
$table-text-color: $gray-color;
$table-line-color: $gray-color;
$table-pager-color: white;
$table-pager-background-color: $box-secondary-color;

$tab-highlight-color: #4466f4;

$selected-color: white;
$selected-width: 2px;

$form-text-color: white;
$form-background-color: #304174;
$form-border-color: $gray-color;

$button-color: white;
$button-background-color: $box-tertiary-color;
$button-text-color: $gray-color;
$button-hover-text-color: $yellow-color;
$button-hover-background-color: $box-tertiary-color;

$box-shadow: 0px 3px 3px
  transparentize(
    $color: $primary-background,
    $amount: 0.3,
  );

$ring1-color: #ff106e;
$ring2-color: #f6be50;
$ring3-color: #4c87f7;
$ring4-color: $hazard-color;
