@import "../../styles/variables";
.IncidentHistoryList {
  display: block;

  .content_row {
    background-color: $box-color;
    display: flex;
    height: calc(100vh - 275px);
    border: $border-width solid $border-color;
    border-radius: $rounded-border-radius;

    box-shadow: $box-shadow;
    margin-left: 0.5rem;
    padding: 1rem;
    .grid {
      width: 100%;
      .download {
        position: absolute;
        bottom: -10px;
        right: 10px;
      }
    }
  }
}
