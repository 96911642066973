.ChartLegend {
  height: 75px;
  display: block;
  margin-top: 15px;
  overflow-y: auto;
  margin: 0 auto;

  .legend-item {
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.responsive-container-pie-chart {
  margin: 0 auto;
}
