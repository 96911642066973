.DailyOpsEmployeeHeartRateTableView {
  display: grid;
  row-gap: 10px;
  .table-icon {
    height: 15px;
    width: auto;
    cursor: pointer;
  }
  .table-icon-large {
    height: 20px;
    width: auto;
    cursor: pointer;
  }
  .table-sort-icon {
    margin-left: 5px;
  }
  .table-sort-cell {
    cursor: pointer;
  }
}
