@import "../../styles/variables";
.Summary {
  background-color: $box-color;
  border: $border-width solid $border-color;
  border-radius: $rounded-border-radius;
  box-shadow: $box-shadow;
  color: $text-color;
  flex-grow: 1;
  padding: 0.15rem;
  margin: 0.5rem 0.25rem;
  &.selectable {
    cursor: pointer;
  }
  &.active,
  &.selectable:hover {
    border: $border-width solid $selected-color;
  }

  .summary_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5rem;
    height: 120px;

    .name {
      font-size: 20px;
      margin: 0.5rem 1rem;
      text-align: left;
      color: $text-color;
      text-transform: capitalize;
      font-weight: bold;
    }
    .value {
      text-align: center;
      margin: 0.5rem 1rem;
      color: $text-color;
      font-weight: 600;
      font-size: 30px;
      line-height: 2.5rem;
      &.falls {
        color: $fall-color;
      }
      &.near-miss {
        color: $near-miss-color;
      }
      &.hazard {
        color: $hazard-color;
      }
      &.hr-alert {
        color: $hr-color;
      }
    }

    .icon {
      margin-top: 16px;
      margin-right: 40px;
      float: left;
    }
    .footer {
      margin: 0.5rem 1rem;
      color: $gray-color;

      .left {
        float: left;
      }
      .right {
        float: right;
      }
    }
  }
}
