.BatteryContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;


  .batteryOuter {
    border-radius: 3px;
    border: 1px solid #fff;
    padding: 1px;
    width: 25px;
    height: 10px;
  }

  .batteryBump {
    border-radius: 2px;
    background-color: #fff;
    margin: 2px;
    width: 1px;
    height: 3px;
  }

  #batteryLevel {
    border-radius: 2px;
    background-color: #73ad21;
    height: 7px;
  }
}
